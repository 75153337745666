import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { LogoIcon } from '../../atoms/Icons/LogoIcon';
import { Link as RouterLink } from '../../atoms/Link/Link';
import styles from './Footer.css';
import elPaisStyles from './ElPaisFooter.css';

interface ElPaisFooterProps {
    showCookieSettingButton: boolean;
    client: string;
}

class ElPaisFooterBase extends React.PureComponent<ElPaisFooterProps & WithTranslation> {
    render() {
        const { t, showCookieSettingButton, client } = this.props;
        const clientAlias = client === 'as' ? 'AS' : 'EL_PAIS';
        let supportUrl = 'https://elpais.com/estaticos/contacte/envio/contactoC.pl';
        let cookiePolicyUrl =  'https://elpais.com/politica-de-cookies/';
        let legalUrl = 'https://elpais.com/estaticos/aviso-legal/';
        let privacyPolicyUrl = 'https://elpais.com/estaticos/politica-privacidad/';

        if (client === 'as') {
            supportUrl = 'https://as.com/formularios/contacto.pl';
            cookiePolicyUrl = 'https://as.com/politica-de-cookies.html';
            legalUrl = 'https://as.com/diarioas/aviso_legal.html';
            privacyPolicyUrl = 'https://as.com/diarioas/politica_privacidad.html';
        }

        return (
            <FooterInner>
                <Container>
                    <Row>
                        <LogoColumn>
                            <LabelRow>En colaboración con Arkadium</LabelRow>
                            <LogoLink
                                href="https://www.arkadium.com/"
                                aria-label="Arkadium Logo"
                                target="_blank"
                            >
                                <LogoIcon />
                            </LogoLink>
                        </LogoColumn>

                        <LinksColumn>
                            <Row>
                                <ItemColumn>
                                    <GlobalLink
                                        rel="noreferrer nofollow"
                                        href={privacyPolicyUrl}
                                        target="_blank"
                                    >
                                        {t('PRIVACY_BTN')}
                                    </GlobalLink>
                                </ItemColumn>

                                <ItemColumn extraClasses={'order-xl-2 order-md-2 order-3'}>
                                    <GlobalLink
                                        rel="noreferrer nofollow"
                                        href={legalUrl}
                                        target="_blank"
                                    >
                                        {t(`${clientAlias}_AVISO_LEGAL`)}
                                    </GlobalLink>
                                </ItemColumn>

                                <ItemColumn extraClasses={'order-xl-3 order-md-3 order-5'}>
                                    <GlobalLink rel="noreferrer nofollow" href={supportUrl} target="_blank">
                                        {t('CONTACT_US_BTN')}
                                    </GlobalLink>
                                </ItemColumn>

                                <ItemColumn extraClasses={'order-xl-4 order-md-7 order-2'}>
                                    <GlobalLink
                                        href="https://corporate.arkadium.com/arena"
                                        target="_blank"
                                    >
                                        {t('PUBLISHERS_BTN')}
                                    </GlobalLink>
                                </ItemColumn>

                                <ItemColumn extraClasses={'order-xl-5 order-md-4 order-4'}>
                                    <GlobalLink
                                        rel="noreferrer nofollow"
                                        href={cookiePolicyUrl}
                                        target="_blank"
                                    >
                                        {t(`${clientAlias}_COOKIES`)}
                                    </GlobalLink>
                                </ItemColumn>

                                {client !== 'elpais' && (
                                    <ItemColumn extraClasses={'order-xl-6 order-md-5 order-6'}>
                                        <Link to={`/${t('ROUTES.HELP')}`}>{t('HELP_PAGE_LINK')}</Link>
                                    </ItemColumn>
                                )}

                                <ItemColumn extraClasses={'order-xl-7 order-md-6 order-7'}>
                                    <button id="ot-sdk-btn" className="ot-sdk-show-settings" onClick={this.cookiePopup}>
                                        {' '}
                                        Configuración de cookies
                                    </button>
                                </ItemColumn>
                            </Row>
                        </LinksColumn>
                    </Row>
                </Container>
            </FooterInner>
        );
    }

    private cookiePopup() {
        if ((window as any).Didomi) {
            (window as any).Didomi.preferences.show();
        }
    }
}

const Container = (props) => <div className={styles.customContainer} {...props} />;
const FooterInner = (props) => <footer className={styles.footerInner} {...props} />;
const Row = (props) => <div className="row" {...props} />;
const LogoColumn = (props) => <div className="col-xs-12 col-md-5 col-lg-4 order-2 order-md-0" {...props} />;
const LinksColumn = (props) => <div className="col-xs-12 col-md-7 col-lg-8" {...props} />;
const ItemColumn = (props) => (
    <div className={`col-xs-12 col-md-6 col-xl-4 offset-lg-2 ${elPaisStyles.columnElP} ${props.extraClasses || ''} ${styles.itemColumn}`} {...props} />
);
const LabelRow = (props) => <div className={styles.labelRow} {...props} />;
const LogoLink = (props) => (
    <a className={styles.logoLink} {...props}>
        {props.children}
    </a>
);
const GlobalLink = (props) => (
    <a className={styles.link} {...props}>
        {props.children}
    </a>
);
const Link = (props) => <RouterLink className={styles.link} {...props} />;

export const ElPaisFooter = withTranslation()(ElPaisFooterBase);
